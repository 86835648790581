<template>
  <div>
    <sidebar></sidebar>
    <div class="main-content">
      <div class="container-fluid">
        <div :class="centerContent ? 'row justify-content-center' : ''">
          <div :class="centerContent ? 'col-12 col-lg-10 col-xl-8' : ''">
            <div class="header">
              <div class="container-auto anchor-header">
                <!-- Body -->
                <div class="header-body">
                  <div class="row align-items-end">
                    <div class="col">
                      <!-- Pretitle -->
                      <h6 class="header-pretitle text-uppercase">
                        {{ headerPreTitle }}
                      </h6>

                      <!-- Title -->
                      <h1 class="header-title">
                        {{ headerTitle }}
                      </h1>
                    </div>
                    <div class="col-auto">
                      <!-- Button -->
                      <slot name="page-action-button"></slot>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "./partials/Sidebar";

export default {
  components: {Sidebar},
  props: {
    headerPreTitle: String,
    headerTitle: String,
    centerContent: Boolean,
  }
};
</script>
<style>
.swal2-styled{
  background-color: #2c7be5 !important;
}

.raise:hover,
.raise:focus {
  transform: translateY(-0.25em);
}
</style>
