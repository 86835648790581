<template>
  <div class="dropup">
    <!-- Toggle -->
    <a
        href="#"
        id="sidebarIconCopy"
        class="dropdown-toggle"
        role="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
    >
      <div class="avatar avatar-sm avatar-online">
        <img
            src="../../../assets/img/avatars/profiles/nome-user.png"
            class="avatar-img rounded-circle"
            alt="..."
        />
      </div>
    </a>

    <!-- Menu -->
    <div class="dropdown-menu" aria-labelledby="sidebarIconCopy">
      <router-link class="dropdown-item cursor-pointer" :to="`/config/user/${user.id}/edit`">
        Perfil
      </router-link>
      <hr class="dropdown-divider"/>
      <button @click="logout()" class="dropdown-item">Sair</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserActions",

  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
      this.$store.dispatch('extension/logout', true);
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    }
  }
}
</script>

<style scoped>

</style>