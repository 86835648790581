<template>
  <div>
    <div class="form-group">
      <label for="nameInput">Nome</label>
      <input type="text"
             class="form-control"
             :class="'name' in errors ? 'is-invalid': ''"
             id="nameInput"
             v-model="user.name"
             placeholder="Nome de usuário">
      <div v-if="'name' in errors" class="invalid-feedback">
        <span v-for="error in errors.name" :key="error">
          {{ error }}
        </span>
      </div>
    </div>
    <div class="form-group">
      <label for="emailInput">E-mail</label>
      <input type="text"
             class="form-control"
             id="emailInput"
             v-model="user.email"
             placeholder="Endereço de e-mail">
    </div>
    <div class="form-group">
      <label for="extensionInput">Ramal</label>
      <input type="text"
             class="form-control"
             id="extensionInput"
             v-model.lazy="user.extension.extension_number"
             placeholder="Ramal">
    </div>
    <div class="form-group">
      <label for="passwordInput">Senha</label>
      <input type="password"
             class="form-control"
             v-model="user.password"
             id="passwordInput">
    </div>
    <div class="form-group">
      <label for="confirmPasswordInput">Informe-a novamente para confirmar</label>
      <input type="password"
             class="form-control"
             v-model="user.confirmPassword"
             id="confirmPasswordInput">
    </div>
    <hr>

    <button type="submit" class="btn btn-primary" @click="updateUser()">Salvar</button>

    <div class="d-flex justify-content-center mt-5">
      <router-link class="align-self-center" to="/config/users">
        <span class="text-muted">{{ backButtonLabel }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditUserForm",

  props: {
    userId: {
      required: true,
    }
  },

  data() {
    return {
      user: {
        extension: {
          extension_number: '',
        }
      },
      backButtonLabel: 'Cancelar edição',
    }
  },

  computed: {
    userToEdit() {
      return this.$store.getters['users/getUserToEdit'];
    },
    errors() {
      return this.$store.getters['users/getUpdateUserErrors'];
    },
    updatedStatus() {
      return this.$store.getters['users/getUpdatedUserStatus'];
    }
  },

  watch: {
    userToEdit() {
      this.user = this.userToEdit;
    },
    updatedStatus() {
      if (this.updatedStatus) {
        this.$swal('Edição concluida!', 'Usuário foi editado com sucesso!', 'success')
            .then(() => {
              this.backButtonLabel = 'Voltar';
            })
      }
    }
  },

  methods: {
    updateUser() {
      const payload = {
        name: this.user.name,
        extension_number: this.user.extension.extension_number,
        email: this.user.email,
        password: this.user.password,
        confirm_password: this.user.confirmPassword,
        role: this.user.role.name,
        timezone: this.user.settings.timezone,
        date_format: this.user.settings.date_format,
        hour_format: this.user.settings.hour_format,
      };

      this.$store.dispatch('users/fetchUpdateUser', {userId: this.user.id, payload: payload})
    }
  },

  created() {
    this.$store.dispatch('users/fetchUser', this.userId);
  }
}
</script>

<style scoped>
.btn {
  width: 100%;
}
</style>