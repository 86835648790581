<template>
  <nav
      class="navbar navbar-vertical fixed-start navbar-expand-md navbar-light"
      id="sidebar"
  >
    <div class="container-fluid">
      <!-- Toggler -->
      <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarCollapse"
          aria-controls="sidebarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Brand -->
      <RouterLink class="navbar-brand p-0" to="/dashboard">
        <div class="text-center">
          <img
              src="../../../assets/img/logo/qevo_blue.svg"
              class="img-fluid logo"
              alt="..."
          />
        </div>
      </RouterLink>

      <!-- User (xs) -->
      <div class="navbar-user d-md-none">
        <!-- Dropdown -->

        <div class="dropdown">
          <!-- Toggle -->
          <a
              href="#"
              id="sidebarIcon"
              class="dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
          >
            <div class="avatar avatar-sm avatar-online">
              <img
                  src="../../../assets/img/avatars/profiles/nome-user.png"
                  class="avatar-img rounded-circle"
                  alt="..."
              />
            </div>
          </a>

          <!-- Menu -->
          <div
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="sidebarIcon"
          >
            <button @click="logout()" class="dropdown-item">Sair</button>
          </div>
        </div>
      </div>

      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="sidebarCollapse">
        <!-- Navigation -->
        <ul class="navbar-nav">
          <li class="nav-item">
            <RouterLink to="/dashboard" class="nav-link" :class="activeRoute === 'dashboard' ? 'active' : ''">
              <i class="fe fe-home"></i> Dashboard do dia
            </RouterLink>
          </li>
          <li class="nav-item">
            <a
                class="nav-link"
                href="#sidebarReports"
                data-bs-toggle="collapse"
                role="button"
                :aria-expanded="activeRoute === 'calls_report'"
                aria-controls="sidebarReports"
            >
              <i class="fe fe-file"></i> Relatórios
            </a>
            <div class="collapse" :class="activeRoute === 'calls_report' ? 'show' : ''" id="sidebarReports">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <RouterLink to="/reports/calls" class="nav-link"
                              :class="activeRoute === 'calls_report' ? 'active' : ''">
                    Chamadas
                  </RouterLink>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a
                class="nav-link"
                href="#sidebarSettings"
                data-bs-toggle="collapse"
                role="button"
                :aria-expanded="activeRoute === 'config_users'
                || activeRoute === 'config_user_edit'
                || activeRoute === 'config_qualifications'"
                aria-controls="sidebarSettings"
            >
              <i class="fe fe-settings"></i> Configurações
            </a>
            <div class="collapse"
                 :class="activeRoute === 'config_users'
                 || activeRoute === 'config_user_edit'
                 || activeRoute === 'config_qualifications'
                 || activeRoute === 'config_qualifications_edit'
                 || activeRoute === 'config_qualifications_create'? 'show' : ''"
                 id="sidebarSettings">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <router-link to="/config/users"
                               class="nav-link"
                               :class="activeRoute === 'config_users'
                               || activeRoute === 'config_user_edit'
                               ? 'active' : ''">
                    Usuários
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/config/qualifications"
                               class="nav-link"
                               :class="activeRoute === 'config_qualifications'
                                || activeRoute === 'config_qualifications_edit'
                                || activeRoute === 'config_qualifications_create'
                                ? 'active' : ''">
                    Qualificações
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <div class="mt-auto"></div>
        <div class="row g-0 border-top">
          <div class="col-6 py-4 text-center">
            <h6 class="text-uppercase text-muted">
              Plano
            </h6>
            <span class="item-status badge bg-light" data-bs-toggle="" data-bs-placement="top" title="">Pré-Pago</span>
          </div>
          <div class="col-6 py-4 text-center border-start">
            <h6 class="text-uppercase text-muted">
              Saldo
            </h6>
            <span class="item-status badge bg-success-soft" data-bs-toggle="modal" data-bs-target="#balanceModal" data-bs-placement="top" title="">R$ {{ formatMoney() }}</span>
          </div>
        </div>
        <!-- User (md) -->
        <div class="navbar-user d-none d-md-flex" id="sidebarUser">
          <!-- User Actions -->
          <user-actions></user-actions>
        </div>
      </div>
      <!-- / .navbar-collapse -->


    </div>
  </nav>
</template>

<script>
import UserActions from "./UserActions";

export default {
  name: 'sidebar',
  components: {UserActions},

  computed: {
    activeRoute() {
      return this.$store.getters['router/getRoute'];
    },
    user() {
      return this.$store.getters['auth/user']
    }
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    },
    formatMoney() {
      let balance = String(this.user.company.balance.toFixed(2))
      balance = balance.replaceAll(',', '')
      return balance.replaceAll('.', ',')
    }
  }
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="logo"] {
    height: 50px !important;
  }
}

.logo {
  height: 80px !important;
}

.bg-success-soft {
  cursor: pointer;
}
</style>
