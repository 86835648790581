<template>
  <layout header-pre-title="Configurações" header-title="Editar Usuário">
    <template v-slot:content>
      <edit-user-form :user-id="userId"></edit-user-form>
    </template>
  </layout>
</template>

<script>
import layout from "../../../components/layout/Layout";
import EditUserForm from "../../../components/configs/users/EditUserForm";

export default {
  components: {
    EditUserForm,
    layout
  },

  name: "EditUserPage",

  computed: {
    userId() {
      return this.$route.params.userId;
    }
  }
}
</script>

<style scoped>

</style>